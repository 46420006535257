
const storeNamesapce='zhikangle';

function getTimestamp() {
    return new Date().getTime();
}

export function setStorage (val:any,module?:string|undefined):boolean {
    if(window.localStorage){
        let newValue:any = {};
        if(typeof module === "undefined") newValue=val;
        else newValue[module] = val;

        let oldStore = getStorage() || {};
        let newStore= Object.assign(oldStore,newValue);
        newStore.timestamp = getTimestamp();
        localStorage.setItem(storeNamesapce,JSON.stringify(newStore));
        return true;
    }else {
        return false
    }
}

export function getStorage(module?:string):any {
    if(window.localStorage && localStorage[storeNamesapce]){
        let data = JSON.parse(localStorage.getItem(storeNamesapce) as string);
        if(!data) return {}

        if(module) {
            return data.module || {};
        }else {
            return data
        }
    }
    return {}
}
