












import {
    Component,
    Watch,
    Vue
} from 'vue-property-decorator';
import TopSection from '@/views/small_screen/top-section.vue';
import BottomSection from '@/views/small_screen/bottom-section.vue';

// tool
import mqtt from 'mqtt';
import {throttle, deepMerge} from '@/util/helper';
import {getStorage, setStorage} from '@/util/localstorage';

// type
import IDeviceData from '@/views/small_screen/type/status.d.ts';

@Component({
    name: 'small_screen',
    components: {
        TopSection,
        BottomSection
    }
})
export default class extends Vue {

    deviceSN = ''
    deviceTopic = ''

    deviceData: Partial<IDeviceData> = {
        type: 1,
        "device_data": {
            "online_status": 0,
            "device_status": 0,
            "bed_status": 2
        },
        "body_data": {
            "hear_rate": 0,
            "breath_rate": 0,
            "motion": 1
        },
        sn: '00',
        time: new Date().getTime()
    }

    deviceDataMqtt: Partial<IDeviceData> = {
        type: 1,
        "device_data": {
            "online_status": 0,
            "device_status": 0,
            "bed_status": 2
        },
        "body_data": {
            "hear_rate": 0,
            "breath_rate": 0,
            "motion": 1
        },
        sn: '00',
        time: new Date().getTime()
    }

    defaultWidth = 1080;
    defaultHeight = 700;
    boxStyle = {
        left: '0',
        top: '0',
        width: `${this.defaultWidth}px`,
        height: `${this.defaultHeight}px`,
        transform: `scale(1)`,
    }

    @Watch('deviceDataMqtt')
    changeDeviceDataMqtt() {
        this.updateDeviceData();
    }

    created() {
        /* test sn: 0100500749*/
        this.deviceSN = (this.$route.query?.sn || '') as string;
        this.deviceTopic = `sleeping_belt_device/${this.deviceSN}`;
        this.createMqtt();
    }

    mounted() {
        window.addEventListener('resize', throttle(this.setScreenScale, 60))
        this.setScreenScale();
    }

    createMqtt() {
        /*todo：封装回调函数*/

        let mqtt_url = this.$cfg.mqttOption.url;
        let mqtt_option:any = {}
        if(this.$cfg.mqttOption.username && this.$cfg.mqttOption.password) {
            mqtt_option = {
                username: this.$cfg.mqttOption.username,
                password: this.$cfg.mqttOption.password
            };
        }
        const client = mqtt.connect(mqtt_url,mqtt_option)

        client.on('connect', () => {
            console.log('mqtt connect')
            client.subscribe(this.deviceTopic, function (err) {
                if (err) {
                    console.log('subscribe error', err)
                }
            })
            client.subscribe('test-topic', function (err) {
                if (err) {
                    console.log('subscribe error', err)
                }
            })
        })

        client.on('message', (topic, message) => {
            switch (topic) {
                case this.deviceTopic:
                    // console.log(topic);
                    console.log(JSON.parse(message.toString()));
                    this.deviceDataMqtt = JSON.parse(message.toString());
                    break;
                case 'test-topic':
                    // console.log(topic)
                    break;
                default:
                    break;
            }
        })

        client.on('error', function (err: any) {
            console.log('error')
            console.log(err)
            // client.end()
        })
        client.on('reconnect', function (err: any) {
            console.log('reconnenct')
            console.log(err)
            // client.end()
        })
        client.on('offline', function (err: any) {
            console.log('offline')
            console.log(err)
            // client.end()
        })
    }

    updateDeviceData() {
        this.deviceData = this.mergeDeviceData();
    }

    mergeDeviceData(): IDeviceData {
        let LSModuleName = 'sm_screen';
        let prevData = getStorage(LSModuleName);
        let newData: IDeviceData =!prevData? this.deviceData: deepMerge(prevData, this.deviceDataMqtt)

        setStorage(newData, LSModuleName);

        return newData;
    }

    destroy() {
        window.removeEventListener('resize', throttle(this.setScreenScale, 60))
    }

    setScreenScale() {
        let winWidth = document.documentElement.offsetWidth || document.body.offsetWidth;
        let winHeight = document.documentElement.offsetHeight || document.body.offsetHeight;
        let winRatio = (winWidth / winHeight).toFixed(5);
        let defaultRatio = (this.defaultWidth / this.defaultHeight).toFixed(5);
        let boxLeft, boxTop, boxScale;

        // console.log(winWidth,winHeight);
        // console.log(winRatio,defaultRatio, (winRatio/defaultRatio));
        if (winRatio < defaultRatio) {
            boxScale = (winWidth / this.defaultWidth).toFixed(5);
        } else {
            boxScale = (winHeight / this.defaultHeight).toFixed(5);
        }

        boxLeft = ((winWidth - this.defaultWidth) / 2).toFixed(5)
        boxTop = ((winHeight - this.defaultHeight) / 2).toFixed(5);
        Object.assign(this.boxStyle, {
            transform: `scale(${boxScale})`,
            left: `${boxLeft}px`,
            top: `${boxTop}px`
        })
    }
}
