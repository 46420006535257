export function throttle(fn: Function, delay: number, mustRunDelay = 0) {
    let timer = setTimeout(() => {
    });
    let tStart: number;
    return function (this: any) {
        const context: any = this;
        const args = arguments;
        const tCurr = +new Date();
        clearTimeout(timer);
        if (!tStart) {
            tStart = tCurr;
        }
        if (mustRunDelay !== 0 && tCurr - tStart >= mustRunDelay) {
            fn.apply(context, args);
            tStart = tCurr;
        } else {
            timer = setTimeout(function () {
                fn.apply(context, args);
            }, delay);
        }
    };
}

export function getElRect(el: HTMLElement) {
    if (!el || el.nodeType !== 1) return 'This Element is not Tags.';

    return el.getBoundingClientRect();
}

/* 配合await使用 */
export function sleep(time: number) {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(1)
        }, time);
    })
}

export function type(data: unknown) {
    if (!data) return false
    let result = Object.prototype.toString.call(data)
    return (result.match(/\[object\s(.+)\]/) as string[])[1].toLowerCase()
    /**
     * @return [object,array,function,number,string,date,regexp,map,set,symbol]
     * */
}

export function deepMerge<T>(obj1: T, obj2: T): T {
    /**
     * 合并策略：
     * 1. 顶级参数有非对象的，不做合并，返回obj2;
     * 2. obj2具有的子(孙)项，在obj1中没有的，则添加入obj1;
     * 3. 两者均有的子(孙)项：均为对象的递归合并；有一方非对象的，obj2的子(孙)项覆盖obj1的子(孙)项。
     * */
    if (type(obj1) === 'object' && type(obj2) === 'object') {
        for (let key in obj2) {
            if (obj1[key]) {
                obj1[key] = deepMerge(obj1[key], obj2[key]);
            } else {
                obj1[key] = obj2[key];
            }
        }
    } else {
        return obj2
    }

    return obj1;
}