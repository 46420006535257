






























































































import {
    Component,
    Prop,
    Watch,
    Vue
} from 'vue-property-decorator';


// type
import IDeviceData from '@/views/small_screen/type/status.d.ts';
import * as statusMap from './type/status_map';

@Component({
    name: 'small_top_section'
})
export default class extends Vue {
    @Prop({default: {}}) deviceData!: IDeviceData;

    isOnLine = false;
    deviceStatus = '';
    bedStatus = '';
    motionStatus = '';


    @Watch('deviceData')
    changeDeviceData() {
        this.updateStatusData();
    }

    mounted() {
        // console.log(statusMap.deviceStatus)
        this.updateStatusData();
    }

    updateStatusData() {
        // console.log(this.deviceData);
        switch (this.deviceData.type) {
            case statusMap.dataType.device:
                this.isOnLine = this.deviceData.device_data?.online_status === 1
                this.deviceStatus = statusMap.deviceStatus[this.deviceData.device_data?.device_status || 0];
                this.bedStatus = statusMap.bedStatus[this.deviceData.device_data?.bed_status || 2];
                break;
            case statusMap.dataType.body:
                this.motionStatus = statusMap.motion[this.deviceData.body_data?.motion || 1];
                break;
            default:
                break;
        }

        // console.log(this.deviceStatus)
    }

}
