





























	import {
		Component,
        Prop,
        Watch,
		Vue
	} from 'vue-property-decorator';

	//type
    import IDeviceData from '@/views/small_screen/type/status.d.ts';
    import * as statusMap from './type/status_map';
    import {getStorage, setStorage} from "@/util/localstorage";
    import {deepMerge} from "@/util/helper";

    interface DataItem {
        xAxis: string;
        data:number | null;
        timestamp: null | number
    }

    interface MqttDataItem extends DataItem{
        timestamp: number;
    }

	@Component({
		name: 'small_bottom_section'
	})
	export default class extends Vue {
	    @Prop({default: {random: 0,data: {}}}) deviceData!:IDeviceData

        heartbeatChartData = {};
        breatheChartData = {};

        maxShowCount = 12;

        breatheData:any[] = new Array(this.maxShowCount).fill({
            xAxis: ' ',
            data: null,
            timestamp: null
        })

        heartbeatData:any[] = new Array(this.maxShowCount).fill({
            xAxis: ' ',
            data: null,
            timestamp: null
        })

        curDeviceData:any = {
            heartbeat: null,
            breathe: null
        }

        chartTheme = {
            grid: {
                show: true,
                borderWidth: 0,
                backgroundColor: 'rgba(0,0,0,0.2)',
                containLabel: true
            },
            categoryAxis: {
                axisLabel: {
                    fontSize: '12px'
                },
                axisLine: {
                    show: false,
                },
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    interval: 1,
                    lineStyle: {
                        color: ['transparent','#fff','#fff','#fff','#fff','#fff','transparent'],
                        type: [3,5], // 虚线样式[线，间隔]
                        opacity: '0.5'
                    },
                }
            },
            valueAxis: {
                nameTextStyle: {
                    color: '#ffffff'
                },
                axisLabel: {
                    fontSize: '12px'
                },
                splitLine: {
                    show: false
                }
            },
        }

        @Watch('deviceData')
        changeUpdateCommand() {
            console.log(this.deviceData.type);
            console.log(statusMap.dataType[this.deviceData.type]);
            if(this.deviceData.type === statusMap.dataType.body) {
                this.curDeviceData = {
                    heartbeat: this.deviceData.body_data?.hear_rate,
                    breathe: this.deviceData.body_data?.breath_rate
                }
                this.setBreatheChart();
                this.setHeartBeatChart();

                console.log(this.curDeviceData)
            }
        }

        created() {

        }

        mounted() {
            this.setBreatheChart();
            this.setHeartBeatChart();
        }

        setBreatheChart() {
            console.log(this.deviceData)
            this.breatheData = this.buildFIFOArray(this.breatheData,{
                xAxis: ' ',
                data: this.deviceData.body_data?.breath_rate,
                timestamp: this.deviceData.time*1000
            })

            this.breatheChartData = {
                grid: {
                    top: '10%',
                    left: '1%',
                    right: '1%',
                    bottom: '1%',
                },
                xAxis: {
                    type: 'category',
                    data: this.breatheData.map(i=>i.xAxis),
                },
                yAxis: {
                    type: 'value',
                },
                animation: false,
                series: [
                    {
                        data: this.breatheData.map(i=>i.data),
                        type: 'line'
                    }
                ]
            };
        }

        setHeartBeatChart() {
            this.heartbeatData = this.buildFIFOArray(this.heartbeatData,{
                xAxis: ' ',
                data: this.deviceData.body_data.hear_rate,
                timestamp: this.deviceData.time*1000
            })


            this.heartbeatChartData = {
                grid: {
                    top: '10%',
                    left: '1%',
                    right: '1%',
                    bottom: '1%',
                },
                xAxis: {
                    type: 'category',
                    data: this.heartbeatData.map(i=>i.xAxis),
                },
                yAxis: {
                    type: 'value',
                    // name: '(人数)',
                },
                animation: false,
                series: [
                    {
                        data: this.heartbeatData.map(i=>i.data),
                        type: 'line'
                    }
                ]
            };
        }

        buildFIFOArray(array:any[],item:any):any[] {
            array.shift();
            array.push(item);
            return array.map((i:any)=>{
                let time = i.timestamp === null ? null : Math.floor((item.timestamp - i.timestamp)/1000);
                return {
                    xAxis: time && time>0 ? `-${time}s`: time === 0?'0s': ' ',
                    data: i.data,
                    timestamp: i.timestamp
                }
            })
        }



	}
