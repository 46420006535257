
export enum dataType {
    device = 1,
    body,
}


export enum onlineStatus {
    off,
    on
}

export enum deviceStatus {
    normal,
    lineError,
    signalError,
    environmentError,
    deviceStopUse
}

export enum bedStatus {
    abnormal,
    on,
    off
}

export enum motion {
    normal,
    signalSearch,
    signalWeak,
    moveLittle,
    moveNormal,
    moveStrong
}